<template>
    <div>
        <b-modal :id="'modalerror-'+group.id"
                 title="Erreur">
            Echec de sauvegarde
        </b-modal>
        <b-modal :id="'modal-'+group.id"
                 ref="modal"
                 title="Sauvegarder le groupe"
                 @show="resetModal"
                 @ok="handleOk">
            <label>Nom du groupe</label>&nbsp;<input type="text" v-model="newgroupname" />
        </b-modal>
        <div>
            <div style="text-align:right">
                <b-button variant="primary" v-show="currentView=='graphic'">Vue graphique <b-icon-file-bar-graph /></b-button>
                <b-button variant="outline-primary" v-show="currentView!='graphic'" @click="changeTab('graphic')">Vue graphique <b-icon-file-bar-graph /></b-button>
                <b-button variant="primary" v-show="currentView=='calendar'">Vue calendrier <b-icon-calendar2-date /></b-button>
                <b-button variant="outline-primary" v-show="currentView!='calendar'" @click="changeTab('calendar')">Vue calendrier <b-icon-calendar2-date /></b-button>
                <b-button variant="primary" v-show="currentView=='day'">Prix par jour <b-icon-calendar2-day /></b-button>
                <b-button variant="outline-primary" v-show="currentView!='day'" @click="changeTab('day')">Prix par jour <b-icon-calendar2-day /></b-button>
            </div>
            <b-nav class="navclass">
                <b-nav-item-dropdown text="Filtre"
                                     class="nav-link"
                                     right>
                    <b-dropdown-item v-show="!(filters.closed)" @click="showClosed">Dates fermées</b-dropdown-item>
                    <b-dropdown-item v-show="(filters.closed)" @click="hideClosed">Dates ouverte seules</b-dropdown-item>
                    <b-dropdown-item v-show="!(filters.guaranted)" @click="showGuaranted">Dates garanties</b-dropdown-item>
                    <b-dropdown-item v-show="(filters.guaranted)" @click="hideGuaranted">Dates non garanties seules</b-dropdown-item>
                    <b-dropdown-item v-show="!(filters.guarantedOnly)" @click="onlyGuaranted">Dates garanties seules</b-dropdown-item>
                    <b-dropdown-item v-show="(filters.guarantedOnly)" @click="showAll">Toutes les dates ouvertes</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Date de début"
                                     class="nav-link"
                                     right>
                    <b-calendar v-model="minQueryDate" :max="maxQueryDate" locale="fr-FR">
                        <b-button size="sm"
                                  variant="outline-danger"
                                  v-if="minQueryDate"
                                  @click="minQueryDate=null">
                            Clear date
                        </b-button>
                    </b-calendar>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Date de fin"
                                     class="nav-link"
                                     right>
                    <b-calendar v-model="maxQueryDate" :min="minQueryDate" locale="fr-FR">
                        <b-button size="sm"
                                  variant="outline-danger"
                                  v-if="maxQueryDate"
                                  @click="maxQueryDate=null">
                            Clear date
                        </b-button>
                    </b-calendar>
                </b-nav-item-dropdown>
                <b-nav-item-dropdown text="Sauvegarde"
                                     class="nav-link"
                                     right>
                    <b-dropdown-item v-show="group.type == 'group'" @click="saveGroup">Enregistrer</b-dropdown-item>
                    <b-dropdown-item @click="saveNewGroup" >Enregistrer sous</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-dropdown-item v-show="group.type == 'group'" @click="editGroup" class="nav-perso">Editer</b-dropdown-item>
            </b-nav>
        </div>
        <DetailChart :isLoaded="isLoaded" :products="products" :dates="dates" :detail="group.name" @selectcol="setDiv" @selectrow="setRow" v-if="currentView=='graphic'"></DetailChart>
        <CalendarView :products="products" v-show="currentView=='calendar'" :minQueryDate="minQueryDate"></CalendarView>
        <CalendarDayView :products="products" v-show="currentView=='day'" :minQueryDate="minQueryDate"></CalendarDayView>
        
        <div class="divcomment">
            <ul>
                <li @click="toggleComment" style="cursor: pointer">
                    Commentaire
                    <b-icon icon="caret-down-fill" v-show="viewComment" /><b-icon icon="caret-left-fill" v-show="!viewComment" />
                </li>
                <li v-show="viewComment">
                    <textarea v-model="comment" class="textcomment"></textarea>
                </li>
            </ul>
        </div>
        <b-tabs ref="tabGroups" content-class="mt-3" justified>
            <b-tab v-for="(product, indexp) in products" :key="indexp" :ref="product.tabName">
                <template #title>
                    {{product.tabName}}
                    <b-icon icon="x-circle-fill" variant="danger" style="float:right" @click="removeProduct(product)"></b-icon>
                </template>
                <div>
                    <div>Site : {{product.webSite.url}}</div>
                    <div>Produit : <a :href="product.urlPage" target="_blank">{{product.code}} ({{product.id}})</a> - {{product.title}}</div>
                    <div>Dur&eacute;e : {{product.duration}} <span v-if="product.duration > 2">jours</span><span v-else>jour</span></div>
                    <div>Pays : <span v-for="country in product.countries" :key="country.code"><img :src="'/flags/'+country.id.toLowerCase()+'.gif'" :alt="country.name" :title="country.name" /></span></div>
                    <div>Mise &agrave; du {{product.updateDate | moment('DD/MM/YYYY')}} </div>
                    <div class="detail-tab" v-if="product.details != undefined && product.details.length > 0">
                        <b-table-simple hover small caption-top responsive>
                            <b-thead head-variant="dark">
                                <b-tr>
                                    <b-th>&nbsp;</b-th>
                                    <b-th>Semaine</b-th>
                                    <b-th>D&eacute;part</b-th>
                                    <b-th>Retour</b-th>
                                    <b-th>Promo</b-th>
                                    <b-th style="text-align: center">Prix TTC</b-th>
                                    <b-th style="text-align: center">Valeur Promo</b-th>
                                    <b-th style="text-align: center">Min participants</b-th>
                                    <b-th>Ouvert</b-th>
                                    <b-th>Garanti</b-th>
                                    <b-th>Import du</b-th>
                                    <b-th>Inchang&eacute; depuis</b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <template v-for="(detail, detailindex) in product.details">
                                    <b-tr :key="detail.id" :class="getIsActiveRow(product.tabName, detail.departureDate, detailindex, detail.registrable) + ' ' + isOpenDate(product, detail)" :id="getRowId(product.tabName, detail.departureDate)" :ref="product.tabName + '_' + detail.departureDate">
                                        <b-td><b-icon-dash-circle-fill v-if="detail.logs.length > 0" class="dropdown" v-on:click="showLog(detail)" v-show="viewLog(detail.id)"></b-icon-dash-circle-fill><b-icon-plus-circle-fill v-if="detail.logs.length > 0" class="dropdown" v-on:click="showLog(detail)" v-show="!detail.showLog"></b-icon-plus-circle-fill></b-td>
                                        <b-td style="border-right: 1px solid grey">{{detail.year}} - {{detail.weekOfTheYear | numeral('00')}}</b-td>
                                        <b-td style="border-right: 1px solid grey">{{detail.departureDate | moment('DD/MM/YYYY')}}</b-td>
                                        <b-td style="border-right: 1px solid grey">{{detail.returnDate | moment('DD/MM/YYYY')}}</b-td>
                                        <b-td :class="classChange(detail, 'detail', 'discount')" style="border-right: 1px solid grey">{{detail.discount == true ? 'oui' : 'non'}}</b-td>
                                        <b-td style="text-align: right; border-right: 1px solid grey">
                                            <!-- b-icon v-show="detail.taxValue > 0" :alt="'Dont Supplément/Taxes : ' + detail.taxValue + ' €'" :title="'Dont Supplément/Taxes : ' + detail.taxValue + ' €'" icon="exclamation-triangle-fill" style="cursor: pointer; float: left"></!--b-icon -->
                                            <b-icon v-show="getSuppl(detail)" :title="getSupplInfo(detail)" icon="exclamation-triangle" style="cursor: pointer; float: left"></b-icon>
                                            <span :inner-html.prop="detail | computeDiffValue('detail', 'value') |  Currency('color')" style="margin-right: 10px"></span>
                                            {{detail.value | Currency}}
                                        </b-td>
                                        <b-td style="text-align: right; border-right: 1px solid grey">
                                            <span v-if="detail.discount" :inner-html.prop="detail | computeDiffValue('detail', 'discountValue') |  Currency('color', true)"  style="margin-right: 10px"></span>
                                            <span v-if="detail.discount">{{detail.discountValue | Currency}}</span>
                                            <span v-else>-</span>
                                        </b-td>
                                        <b-td style="text-align: right; border-right: 1px solid grey">{{detail.minParticipants}}</b-td>
                                        <b-td  style="border-right: 1px solid grey" :class="classChange(detail, 'detail', 'registrable')">{{detail.registrable ? 'oui' : 'non'}}</b-td>
                                        <b-td  style="border-right: 1px solid grey" :class="classChange(detail, 'detail', 'guaranteed')">
                                            <template v-if="detail.guaranteed">
                                                {{ detail.guaranteedDate | moment('DD/MM/YYYY')}}
                                            </template>
                                            <template v-else>
                                                non
                                            </template>
                                        </b-td>
                                        <b-td style="border-right: 1px solid grey">{{detail.updateDate | moment('DD/MM/YYYY')}}</b-td>
                                        <b-td>{{detail.creationDate | moment('DD/MM/YYYY')}}</b-td>
                                    </b-tr>
                                    <template v-for="(log, logindex) in detail.logs">
                                        <b-tr :key="log.id" v-show="detail.showLog" :class="logindex % 2 ? 'subodd' : 'nosubodd'">
                                            <b-td style="border-right: 1px solid grey" colspan="4" v-if="logindex == 0" :rowspan="detail.logs.length">&nbsp;</b-td>
                                            <b-td style="border-right: 1px solid grey" :class="classChange(detail, 'log', 'discount', logindex)">{{log.discount == true ? 'oui' : 'non'}}</b-td>
                                            <b-td style="text-align: right; border-right: 1px solid grey">
                                                <span :inner-html.prop="detail | computeDiffValue('log', 'value', logindex) |  Currency('color')" style="margin-right: 10px"></span>
                                                {{log.value | Currency}}
                                            </b-td>
                                            <b-td style="text-align: right; border-right: 1px solid grey">
                                                <span v-if="log.discount" :inner-html.prop="detail | computeDiffValue('log', 'discountValue', logindex) |  Currency('color', true)"  style="margin-right: 10px"></span>
                                                <span v-if="log.discount">{{log.discountValue | Currency}}</span>
                                                <span v-else>-</span>
                                            </b-td>
                                            <b-td style="text-align: right; border-right: 1px solid grey">{{log.minParticipants}}</b-td>
                                            <b-td style="border-right: 1px solid grey" :class="classChange(detail, 'log', 'registrable', logindex)">{{log.registrable ? 'oui' : 'non'}}</b-td>
                                            <b-td style="border-right: 1px solid grey" :class="classChange(detail, 'log', 'guaranteed', logindex)">
                                                <template v-if="log.guaranteed">
                                                    {{ log.guaranteedDate | moment('DD/MM/YYYY')}}
                                                </template>
                                                <template v-else>
                                                    non
                                                </template>
                                            </b-td>
                                            <b-td style="border-right: 1px solid grey">{{log.importDate | moment('DD/MM/YYYY')}}</b-td>
                                            <b-td style="border-right: 1px solid grey">{{log.previousDate | moment('DD/MM/YYYY')}}</b-td>
                                        </b-tr>
                                    </template>
                                </template>
                            </b-tbody>
                            <b-tfoot>
                                <b-tr>
                                    <b-td colspan="12" variant="secondary" class="text-right">
                                        Nombre de dates: <b>{{product.details.length}}</b>
                                    </b-td>
                                </b-tr>
                            </b-tfoot>
                        </b-table-simple>
                    </div>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { mapState } from "vuex"
    import axios from 'axios'
    import moment from 'moment'
    import DetailChart from './DetailChart'
    import CalendarView from './CalendarView'
    import CalendarDayView from './CalendarDayView'

    export default {
        name: 'TabProduct',
        components: {
            DetailChart,
            CalendarView,
            CalendarDayView
        },
        props: {
            group: Object
        },
        data() {
            return {
                loaded: 0,
                newgroupname: "",
                orderedProducts: [],
                products: [],
                productsRemoved: [],
                mindate: null,
                maxdate: null,
                minQueryDate: null,
                maxQueryDate: null,
                viewComment: false,
                currentView: 'calendar',
                comment: '',
                dates: {},
                isLoaded: false,
                activeRow: '',
                logsInView: {},
                filters: { closed: true, guaranted: true, guarantedOnly: false }
            }
        },
        watch: {
            minQueryDate: function () {
                this.loadProducts();
            },
            maxQueryDate: function () {
                this.loadProducts();
            },
        },
        computed: {
            groupLabel: function () {
                return this.group.label
            },
            ...mapState(["webSites"])
        },
        methods: {
            getSuppl(value) {
                return (value.taxValue > 0 || value.valueSupp > 0)
            },
            getSupplInfo(value) {
                let supInfo = ""
                if (value.taxValue > 0) {
                    supInfo += "Supplément inclus : " + value.taxValue + " €"
                }
                if (value.valueSupp > 0) {
                    if (supInfo.length > 0) {
                        supInfo += '\r\n'
                    }
                    supInfo += "Supplément : " + value.valueSupp + " €"
                }
                return supInfo
            },
            changeTab(value) {
                this.isLoaded = false;
                this.currentView = value;
                this.isLoaded = true;
            },
            resetModal() {
                this.newgroupname = "";
            },
            handleOk() {
                let products = [];
                let group = this.group.group;
                this.products.forEach(product => {
                    if (this.productsRemoved.indexOf(product.id) < 0) {
                        products.push(product.id);
                    }
                });
                let objPost = {
                    id: group.id,
                    label: this.newgroupname,
                    comment: this.comment,
                    Products: [...new Set(products)]
                }
                objPost.Products.sort();
                let self = this
                axios.post(this.$ipService + '/api/groupProducts', objPost
                ).then(function () {
                    self.$store.dispatch('loadGroupcountries')
                    self.$store.dispatch('loadCountries')
                    self.$store.dispatch('loadCountriesActives')
                    self.$store.dispatch('loadGrouptypes')
                    self.$store.dispatch('loadGrouplevels')
                    self.$store.dispatch('loadWebsites')
                    self.$store.dispatch('loadGroupdurations')
                    self.$store.dispatch('loadGroupproducts')
                    self.$store.dispatch('loadGroupsOrProducts')
                    self.$emit('reset')
                }).catch(function () {
                    self.$bvModal.show('modalerror-' + self.group.id)
                }).finally(() => {

                })
            },
            saveNewGroup() {
                this.$bvModal.show('modal-' + this.group.id);
            },
            editGroup() {
                this.$store.commit('setCurrentTab', 'groupproduct')
                this.$store.commit("setGroupEdit", this.group.id);
            },
            saveGroup() {
                let products = [];
                let group = this.group.group;
                this.products.forEach(product => {
                    if (this.productsRemoved.indexOf(product.id) < 0) {
                        products.push(product.id);
                    }
                });
                let objPost = {
                    id: group.id,
                    label: group.label,
                    comment: this.comment,
                    Products: [...new Set(products)]
                }
                objPost.Products.sort();
                let self = this
                axios.post(this.$ipService + '/api/groupProducts/' + objPost.id, objPost
                ).then(function () {
                    self.$store.dispatch('loadGroupcountries')
                    self.$store.dispatch('loadCountries')
                    self.$store.dispatch('loadCountriesActives')
                    self.$store.dispatch('loadGrouptypes')
                    self.$store.dispatch('loadGrouplevels')
                    self.$store.dispatch('loadWebsites')
                    self.$store.dispatch('loadGroupdurations')
                    self.$store.dispatch('loadGroupproducts')
                    self.$store.dispatch('loadGroupsOrProducts')
                    self.$emit('reset')
                }).catch(function () {
                    self.$bvModal.show('modalerror-' + self.group.id)
                }).finally(() => {

                })
            },
            toggleComment() {
                this.viewComment = !this.viewComment
            },
            removeProduct(product) {
                this.productsRemoved.push(product.id)
                this.loadProducts()
            },
            viewLog(detail_id) {
                return this.logsInView[detail_id] != undefined && this.logsInView[detail_id]
            },
            showClosed() {
                this.filters.closed = true
                this.loadProducts();
            },
            hideClosed() {
                this.filters.closed = false
                this.loadProducts();
            },
            showGuaranted() {
                this.filters.guaranted = true
                this.loadProducts();
            },
            hideGuaranted() {
                this.filters.guaranted = false
                this.filters.guarantedOnly = false
                this.loadProducts();
            },
            onlyGuaranted() {
                this.filters.guaranted = true
                this.filters.guarantedOnly = true
                this.loadProducts();
            },
            showAll() {
                this.filters.guarantedOnly = false
                this.loadProducts();
            },
            isOpenDate(product, detail) {
                if (!detail.open) {
                    return "expiredDate"
                }
                let momentA = moment(detail.departureDate.substr(0, 10), "YYYY-MM-DD")
                let momentD = moment(detail.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentP = moment(product.updateDate.substr(0, 10), "YYYY-MM-DD")
                let momentB = moment();
                if (momentA.isBefore(momentB) || momentD.isBefore(momentP)) {
                    return "expiredDate"
                }
                return ""
            },
            getIsActiveRow(producttabName, detaildepartureDate, detailindex, registrable) {
                let currClass = detailindex % 2 ? 'odd' : 'noodd'
                let rowId = this.getRowId(producttabName, detaildepartureDate)
                if (rowId == this.activeRow) {
                    currClass += ' selectedDetail'
                }
                if (!registrable) {
                    currClass += ' disableDetail'
                }
                return currClass
            },
            getRowId(b, c) {
                let id = this.group.name + b + c
                return id.replace(/[^0-9a-z]/gi, '')
            },
            setDiv(tabName) {
                this.$refs[tabName][0].activate()
            },
            setRow(tabName, dateValue) {
                this.activeRow = this.getRowId(tabName, dateValue + 'T00:00:00');
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },
            classChange: function (detail, type, key, index_log) {
                if (type == 'detail' && (detail.logs.length == 0 || detail.logs[0][key] == detail[key])) {
                    return ""
                } else if (type == 'detail') {
                    if (detail[key] != detail.logs[0][key]) {
                        return detail[key] ? "green" : "red"
                    }
                    return ""
                }
                let next_index = index_log + 1
                if (next_index >= detail.logs.length || detail.logs[index_log][key] == detail.logs[index_log + 1][key]) {
                    return ""
                }
                return detail.logs[index_log][key] ? "green" : "red"
            },
            getTabName: function (product) {
                for (let i = 0; i < this.webSites.length; i++) {
                    if (this.webSites[i].adapterId == product.adapterId) {
                        return this.webSites[i].shortname + "-" + product.code
                    }
                }
                return product.code
            },
            showLog: function (detail) {
                detail.showLog = !detail.showLog
                if (this.logsInView[detail.id] != undefined) {
                    this.logsInView[detail.id] = !this.logsInView[detail.id]
                } else {
                    this.logsInView[detail.id] = true
                }
                this.products = [...this.products ]
            },
            getWebSite: function (adapterId) {
                for (let i = 0; i < this.webSites.length; i++) {
                    if (this.webSites[i].adapterId == adapterId) {
                        return this.webSites[i];
                    }
                }
            },
            setGraphDatas: function () {
                if (this.loaded > 0) return;
                this.isLoaded = true
            },
            getDetails: function (self, product, index) {
                let objQuery = { ProductId: product.id, ClosedDate: self.filters.closed, Guaranted: self.filters.guaranted, GuarantedOnly: self.filters.guarantedOnly, DateFrom: self.minQueryDate, DateTo: self.maxQueryDate }
                axios.post(this.$ipService + '/api/Details/data', objQuery).then(function (response) {
                    let lDetails = response.data
                    
                    lDetails.sort((a, b) => {
                        let momentA = moment(a.departureDate.substr(0, 10), "YYYY-MM-DD")
                        let momentB = moment(b.departureDate.substr(0, 10), "YYYY-MM-DD")
                        let dateA = Math.round(momentA.format('x'))
                        let dateB = Math.round(momentB.format('x'))
                        if (self.mindate == null) {
                            self.mindate = momentA
                            self.maxdate = momentB
                        }
                        if (momentA.isBefore(self.mindate)) {
                            self.mindate = momentA;
                        }
                        if (momentB.isBefore(self.mindate)) {
                            self.mindate = momentB;
                        }
                        if (momentA.isAfter(self.maxdate)) {
                            self.maxdate = momentA;
                        }
                        if (momentB.isAfter(self.maxdate)) {
                            self.maxdate = momentB;
                        }

                        if (self.dates[momentA.format("YYYY-MM-DD")] == undefined) {
                            self.dates[momentA.format("YYYY-MM-DD")] = {};
                        }

                        if (self.dates[momentB.format("YYYY-MM-DD")] == undefined) {
                            self.dates[momentB.format("YYYY-MM-DD")] = {};
                        }

                        return dateA - dateB
                    })
                    lDetails.forEach(d => {
                        let mDetail = moment(d.departureDate.substr(0, 10), "YYYY-MM-DD");
                        if (self.dates[mDetail.format("YYYY-MM-DD")] == undefined) {
                            self.dates[mDetail.format("YYYY-MM-DD")] = {}
                        }
                        if (self.dates[mDetail.format("YYYY-MM-DD")][product.code] == undefined) {
                            self.dates[mDetail.format("YYYY-MM-DD")][product.code] = []
                        }
                        self.dates[mDetail.format("YYYY-MM-DD")][product.code] = { p: product, d: d }
                        if (d.logs != undefined && d.logs.length > 1) {
                            d.logs.sort((a, b) => {
                                return (a.importDate.localeCompare(b.importDate)) * -1
                            })
                        }
                    })

                    lDetails.showLog = false
                    // self.details[index] = lDetails
                    product.tabName = self.getTabName(product)
                    product.detailsId = index
                    product.details = lDetails
                    Vue.set(self.products, index, product)
                }).catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    self.loaded--;
                    self.setGraphDatas();
                });
            },
            filterProducts: function () {
                /**
                this.products.forEach((product, index) => {

                })
                **/
            },
            addProduct(product) {
                this.orderedProducts.forEach(w => {
                    if (product.adapterId == w.adapterId) {
                        w.products.push(product)
                    }
                })
                console.log(this.orderedProducts)
            },
            loadProducts: function () {
                this.orderedProducts = []
                this.webSites.forEach(w => {
                    this.orderedProducts.push({ 'adapterId': w.adapterId, 'name': w.name, 'order': w.order, 'products': [] })
                })
                let self = this
                this.products = []
                this.loaded = 0
                this.mindate = null
                this.maxdate = null
                this.dates = { }
                this.isLoaded = false
                this.activeRow = ''
                let index = 0
                this.comment = ''
                this.logsInView = { }
                if (this.group.type == 'group') {
                    axios.get(this.$ipService + '/api/GroupProducts/' + this.group.id
                    ).then(function (response) {
                        self.group.group = response.data
                        this.comment = this.group.group.comment
                        if (this.comment.length > 0) {
                            this.viewComment = true
                        }
                    }).catch(function (error) {
                        console.log(error.response)
                    }).finally(() => {

                    })
                    this.comment = this.group.group.comment
                    if (this.comment.length > 0) {
                        this.viewComment = true
                    }
                    axios.get(this.$ipService + '/api/GroupProducts/' + this.group.id + '/Products'
                    ).then(function (response) {
                        response.data.forEach(product => {
                            if (self.productsRemoved.indexOf(product.id) >= 0) {
                                return
                            }
                            product.webSite = self.getWebSite(product.adapterId)
                            product.details = []
                            //self.products[index] = product
                            self.addProduct(product)
                            self.loaded++;
                            //self.getDetails(self, product, index)
                            index++
                        })
                    }).catch(function (error) {
                        console.log(error)
                    }).finally(() => {
                        let cpt = 0;
                        this.orderedProducts.forEach(w => {
                            w.products.forEach((p) => {
                                self.products[cpt] = p
                                self.getDetails(self, p, cpt)
                                cpt++
                            })
                        })
                    })
                } else if (this.group.type == 'comparaison') {
                    let products = []
                    this.group.products.forEach(productId => {
                        if (self.productsRemoved.indexOf(productId) >= 0) {
                            return
                        }
                        index++
                        axios.get(this.$ipService + '/api/Products/' + productId
                        ).then(function (response) {
                            let product = response.data
                            product.webSite = self.getWebSite(product.adapterId)
                            products.push(product)
                        }).catch(function (error) {
                            console.log(error)
                        }).finally(() => {
                            if (index == this.group.products.length) {
                                products.forEach((product, index) => {
                                    product.webSite = self.getWebSite(product.adapterId)
                                    product.details = []
                                    self.products[index] = product
                                    self.getDetails(self, product, index)
                                    index++
                                })
                            }
                        })
                    });
                }
            }
        },
        mounted: function () {
            this.loadProducts()

        }
    };
</script>

<style scoped>
    .divcomment li {
        list-style: none;
    }
    .textcomment {
        width: 90%;
        height: 100px;
    }
    .navclass {
        border-width: 1px 0px 1px 0px;
        border-style: solid;
    }
    .navclass .nav-link {
        font-size: 12px;
        padding: 5px;
    }
    .dropdown {
        font-size: 20px;
        cursor: pointer;
    }
    .odd {
        background-color: lightgrey
    }
    .subodd {
        background-color: powderblue
    }
    .nosubodd {
        background-color: aliceblue
    }
    .small {
        max-width: 600px;
        margin: 150px auto;
    }
    .red {
        color: red;
    }
    .green {
        color: green;
    }
    .selectedDetail {
        background-color: #ffd800;
    }
    .disableDetail {
        color: orange;
    }
    .expiredDate {
        color: #808080;
    }
    .nav-perso {
        padding-top: 8px;
    }
</style>

<style>
    .nav-perso a {
        color: #007bff !important;
    }
</style>