import Vue from 'vue'
import VueCookies from 'vue-cookies';
import Vuex from 'vuex'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VueGoogleCharts from 'vue-google-charts'
import { CoolSelectPlugin } from 'vue-cool-select'
import 'vue-cool-select/dist/themes/bootstrap.css'


/*global urlApi*/

moment.locale('fr')

function firstDayOfWeek(week, year) {

    if (year == null) {
        year = (new Date()).getFullYear();
    }

    let mDate = moment(firstWeekOfYear(year));
    mDate.add(week - 1, 'weeks');

    return mDate;

}


function firstWeekOfYear(year) {
    let date = new Date();
    date = firstDayOfYear(date, year);
    date = firstWeekday(date);
    return date;
}

function firstDayOfYear(date, year) {
    date.setYear(year);
    date.setDate(1);
    date.setMonth(0);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

/**
 * Sets the given date as the first day of week of the first week of year.
 */
function firstWeekday(firstOfJanuaryDate) {
    // 0 correspond au dimanche et 6 correspond au samedi.
    let FIRST_DAY_OF_WEEK = 6; // Monday, according to iso8601
    let WEEK_LENGTH = 7; // 7 days per week
    let day = firstOfJanuaryDate.getDay();
    day = (day === 0) ? 7 : day; // make the days monday-sunday equals to 1-7 instead of 0-6
    let dayOffset = -day + FIRST_DAY_OF_WEEK; // dayOffset will correct the date in order to get a Monday
    if (WEEK_LENGTH - day + 1 < 4) {
        // the current week has not the minimum 4 days required by iso 8601 => add one week
        dayOffset += WEEK_LENGTH;
    }

    let maDate = new Date(firstOfJanuaryDate.getTime() + dayOffset * 24 * 60 * 60 * 1000);

    return maDate;
}


var ipService = urlApi

Vue.prototype.$ipService = ipService

var numeral = require('numeral')

Vue.config.productionTip = false
Vue.use(VueCookies)
Vue.use(Vuex)

Vue.use(VueGoogleCharts)
Vue.use(CoolSelectPlugin)

Vue.$cookies.config('30d')
import axios from 'axios'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueMoment, {
    moment,
})
numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't'
    },
    ordinal: function (number) {
        return number === 1 ? 'er' : 'ème'
    },
    currency: {
        symbol: '€'
    }
});

numeral.locale('fr')

Vue.filter("Currency", function (value, arg1, invert) {
    if (isNaN(value) || value=='') return value
    if (arg1 != undefined && arg1 == 'color') {
        let cptClass = value > 0 ? 'number_red' : 'number_green'
        if (invert) {
            cptClass = cptClass == 'number_red' ? 'number_green' : 'number_red'
        }
        return '<span class="' + cptClass + '">&nbsp;(' + numeral(value).format('+0,0 $') + ')</span>'
    }
    return numeral(value).format('0,0 $')// displaying other groupings/separators is possible, look at the docs
})

Vue.filter("firstDayOfWeek", function (year, week, format) {
    return firstDayOfWeek(week, year).format(format)
})

Vue.filter("dayOfWeek", function (weekVal, format) {
    let tbWeek = weekVal.split("-");
    return firstDayOfWeek(tbWeek[1], tbWeek[0]).format(format)
})

Vue.filter("numeral", function (value, format) {
    if (isNaN(value) || value == '') return value
    return numeral(value).format(format)
})

Vue.filter("computeDiffValue", function (detail, type, key, index_log) {
    if (type == 'detail' && (detail.logs.length == 0 || detail.logs[0][key] == detail[key])) {
        return ""
    } else if (type == 'detail') {
        return detail[key] - detail.logs[0][key]
    }
    let next_index = index_log + 1
    if (next_index >= detail.logs.length || detail.logs[index_log][key] == detail.logs[index_log + 1][key]) {
        return ""
    }
    return detail.logs[index_log][key] - detail.logs[index_log + 1][key]
})

Vue.filter('truncate', function (text, length, clamp) {
    clamp = clamp || '...';
    var node = document.createElement('div');
    node.innerHTML = text;
    var content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
});



const store = new Vuex.Store({
    state: {
        windowsSize: window.innerHeight,
        webSites: [],
        activesWebSites: [],
        countries: [],
        countriesActives: [],
        groupCountries: [],
        groupDurations: [],
        groupLevels: [],
        groupProducts: [],
        groupTypes: [],
        tabsgroup: {},
        groupsOrProducs: {},
        currentUser: null,
        currentTab: '',
        previousTab: '',
        currentDetail: '',
        allProducts: [],
        groupProductLoading: false,
        groupEdit: 0
    },
    getters: {
        currentuser: state => {
            return state.currentUser
        }
    },
    actions: {
        updateWindowsSize({ commit }) {
            commit('setWindowsSize', window.innerHeight)
        },
        loadWebsites({ commit }) {
            axios.get(ipService +"/api/websites").then(response => {
                commit('setWebsites', response.data)
            })
        },
        loadGroupdurations({ commit }) {
            axios.get(ipService + "/api/groupdurations?jstime=" + Date.now()).then(response => {
                commit('setgroupdurations', response.data)
            })
        },
        loadGroupcountries({ commit }) {
            axios.get(ipService + "/api/GroupCountries/WithProducts?jstime=" + Date.now()).then(response => {
                commit('setgroupcountries', response.data)
            })
        },
        loadCountries({ commit }) {
            axios.get(ipService + "/api/countries?jstime=" + Date.now()).then(response => {
                commit('setcountries', response.data)
            })
        },
        loadCountriesActives({ commit }) {
            axios.get(ipService + "/api/countries/actives?jstime=" + Date.now()).then(response => {
                commit('setcountriesactives', response.data)
            })
        },
        loadGrouptypes({ commit }) {
            axios.get(ipService + "/api/grouptypes?jstime=" + Date.now()).then(response => {
                commit('setgrouptypes', response.data)
            })
        },
        loadGroupproducts({ commit }) {
            commit('setLoadingGroupProduct', true)
            axios.get(ipService + "/api/groupproducts/WithProducts?jstime=" + Date.now()).then(response => {
                commit('setgroupproducts', response.data)
                commit('setLoadingGroupProduct', false)
            })
        },
        loadGrouplevels({ commit }) {
            axios.get(ipService + "/api/grouplevels?jstime=" + Date.now()).then(response => {
                commit('setgrouplevels', response.data)
            })
        },
        loadGroupsOrProducts({ commit }) {
            axios.get(ipService + "/api/products/groupandproducts?jstime=" + Date.now()).then(response => {
                commit('setGroupsOrProducts', response.data)
            })
        },
        addTabGroupAction({ commit }, tabgroup) {
            commit('addTabGroup', tabgroup)
        },
        removeTabGroupAction({ commit }, tabgroup_id) {
            commit('removeTabGroup', tabgroup_id)
        }

    },
    mutations: {
        setGroupEdit(state, groupEdit) {
            state.groupEdit = groupEdit
        },
        setLoadingGroupProduct(state, boolvalue) {
            state.groupProductLoading = boolvalue
        },
        setCurrentTab(state, tab) {
            if (state.currentTab != tab) {
                state.previousTab = state.currentTab
            }
            state.currentTab = tab
        },
        setCurrentDetail(state, detail) {
            state.currentDetail = detail
        },
        setUser(state, user) {
            state.currentUser = user
        },
        setWindowsSize(state, newSize) {
            state.windowsSize = newSize
        }, 
        setWebsites(state, sites) {
            sites.sort((a, b) => {
                return a.order - b.order
            })
            state.webSites = sites
            state.activesWebSites = sites.filter(site => site.enabled)
        },
        setgroupdurations(state, gdurations) {
            state.groupDurations = gdurations
        },
        setgroupcountries(state, countries) {
            state.groupCountries = countries
        },
        setcountries(state, ctries) {
            state.countries = ctries
        },
        setcountriesactives(state, ctries) {
            state.countriesActives = ctries
        },
        setgrouptypes(state, gtypes) {
            state.groupTypes = gtypes
        },
        setgroupproducts(state, gproducts) {
            state.groupProducts = gproducts
        },
        setgrouplevels(state, glevels) {
            state.groupLevels = glevels
        },
        setGroupsOrProducts(state, groupsOrProducs) {
            state.groupsOrProducs = groupsOrProducs
        },
        addTabGroup(state, tabgroup) {
            if (state.tabsgroup[tabgroup.id] == undefined) {
                let newObj = []
                newObj[tabgroup.id] = tabgroup

                state.tabsgroup = { ...state.tabsgroup, ...newObj }
                let pos = 0
                for (const [tabid, tabsgroup] of Object.entries(state.tabsgroup)) {
                    tabsgroup.index = pos
                    state.tabsgroup[tabid] = tabsgroup
                    pos++;
                }
                state.tabsgroup = {
                    ...state.tabsgroup
                }
                if (state.currentDetail == "") {
                    state.currentDetail = tabgroup.name
                }
            }
        },
        removeTabGroup(state, tabgroup_id) {
            let tabsgroup = {}
            if (state.tabsgroup[tabgroup_id] == undefined) {
                return;
            }
            if (state.currentDetail == state.tabsgroup[tabgroup_id].name) {
                state.currentDetail = ""
            }
            delete state.tabsgroup[tabgroup_id]
            let pos = 0
            for (let [tabid, tabgroup] of Object.entries(state.tabsgroup)) {
                if (tabid == tabgroup_id) {
                    continue
                }
                if (state.currentDetail == "") {
                    state.currentDetail = tabgroup.name
                }
                tabgroup.index = pos
                tabsgroup[tabid] = tabgroup
                pos++
            }
            state.tabsgroup = {}
            state.tabsgroup = { ...tabsgroup }
        }
    }
})

new Vue({
    render: h => h(App),
    store
}).$mount('#app')
