<template>
    <b-card title="Modification de zone géographique">
        <b-form>
            <b-form-group id="input-group-1"
                          label="Nom :"
                          label-for="input-1">
                <b-form-input id="input-1"
                              type="text"
                              placeholder="Nom"
                              v-model="groupCountry.label"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" label="Filtre :" label-for="input-3">
                <b-form-select id="input-3"
                               :options="selectOptions"
                               v-model="selectedOption"></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-3" label="Pays :" label-for="input-3">
                <b-form-checkbox-group v-model="selectedCountries"
                                       :options="countriesList"
                                       class="mb-3 fixed-with"
                                       value-field="id"
                                       text-field="name"
                                       disabled-field="notEnabled"></b-form-checkbox-group>
            </b-form-group>
            <b-button type="button" variant="primary" @click="postform">Submit</b-button>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'


    export default {
        name: 'EditZoneGeo',
        components: {
        },
        data() {
            return {
                selectedCountries: [],
                listOptions: [],
                selectedOption: 'empty',
                groupCountry: {},
                selectOptions: [
                    { value: "all", text: "Tous les pays" },
                    { value: "empty", text: "Pays non affectés" },
                    { value: "group", text: "Pays du groupe uniquement" },
                    { value: "groups", text: "Pays déjà affectés uniquement" }
                ],
                action: "list",
                gCountriesFields: [
                    {
                        key: 'label',
                        label: 'Zone Géographique'
                    },
                    {
                        key: 'countries',
                        label: 'Pays associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
            editId: Number
        },
        event: ['reset', 'save'],
        methods: {
            resetform: function () {
                this.$emit('reset')
            },
            postform: function () {
                let objPost = {
                    id: this.groupCountry.id,
                    label: this.groupCountry.label,
                    countries: this.selectedCountries
                }
                let self = this
                axios.post(this.$ipService + '/api/GroupCountries/' + objPost.id, objPost
                ).then(function () {
                    self.$store.dispatch('loadGroupcountries')
                    self.$store.dispatch('loadCountries')
                    self.$store.dispatch('loadCountriesActives')
                    self.$store.dispatch('loadGrouptypes')
                    self.$store.dispatch('loadGrouplevels')
                    self.$store.dispatch('loadWebsites')
                    self.$store.dispatch('loadGroupdurations')
                    self.$store.dispatch('loadGroupproducts')
                    self.$emit('reset')
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {

                })
            },
            getEmptyList: function () {
                let result = []
                this.countriesActives.forEach(ctry => {
                    if (ctry.gCountries.length == 0) {
                        result.push(ctry)
                    }
                    if (ctry.gCountries.indexOf(this.editId) >= 0) {
                        result.push(ctry)
                    }
                })
                return result.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            },
            getAllList: function () {
                return this.countriesActives.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            },
            getGroupList: function () {
                let result = []
                this.countriesActives.forEach(ctry => {
                    if (ctry.gCountries.indexOf(this.editId) >= 0) {
                        result.push(ctry)
                    }
                })
                return result.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            },
            getGroupsList: function () {
                let result = []
                this.countriesActives.forEach(ctry => {
                    if (ctry.gCountries.length > 0) {
                        result.push(ctry)
                    }
                })
                return result.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            }
        },
        computed: {
            countriesList: function () {
                if (this.selectedOption == "all") {
                    return this.getAllList()
                }
                if (this.selectedOption == "empty") {
                    return this.getEmptyList()
                }
                if (this.selectedOption == "group") {
                    return this.getGroupList()
                }
                return this.getGroupsList()
            },
            ...mapState(["webSites", "groupDurations", "countriesActives", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
            let self=this
            axios.get(this.$ipService + '/api/GroupCountries/' + this.editId
            ).then(function (response) {
                self.groupCountry = response.data
            }).catch(function (error) {
                console.log(error);
            }).finally(() => {
            })

            this.selectedCountries = []
            this.countriesActives.forEach(ctry => {
                if (ctry.gCountries.indexOf(this.editId) >= 0) {
                    this.selectedCountries.push(ctry.id)
                }
            })
        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }
</style>
