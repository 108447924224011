<template>
    <b-card title="Modification du groupe de produit" bg-variant="light">
        <b-form>
            <b-form-group id="input-group-nom"
                          label="Nom :"
                          label-for="input-nom"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">
                <b-form-input id="input-nom"
                              type="text"
                              placeholder="Nom"
                              v-model="groupProduct.label" :state="!groupExist"
                              @change="checkGroup"></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-comment" size="sm"
                          label="Commentaire :"
                          label-for="input-comment"
                          label-cols-sm="1" content-cols-sm="4"
                          label-align-sm="right">
                <b-form-textarea id="input-comment"
                                 v-model="groupProduct.comment"
                                 placeholder="Commentaire"
                                 rows="3"
                                 max-rows="6"></b-form-textarea>
            </b-form-group>
            <b-form-group id="input-group-filtre" label="Filtre :" label-for="input-3" size="sm"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">
                <b-form-select id="input-filtre"
                               :options="selectOptions"
                               v-model="selectedOption"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-zonegeo" label="Zone GéoGraphique :" label-for="input-zonegeo" size="sm"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">
                <b-form-select id="input-zonegeo" :disabled="disabledFilter"
                               :options="listZoneGeo"
                               v-model="selectedGC" @change="updateCountries()"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-country" label="Pays :" label-for="input-country" size="sm"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">
                <b-form-select id="input-country" :disabled="disabledFilter"
                               :options="listCountries"
                               v-model="selectedCountry"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-type" label="Type de produit :" label-for="input-type" size="sm"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">
                <b-form-select id="input-type" :disabled="disabledFilter"
                               :options="listGroupTypes"
                               v-model="selectedGroupTypes"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-level" label="Niveau de produit :" label-for="input-level" size="sm"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">
                <b-form-select id="input-level" :disabled="disabledFilter"
                               :options="listGroupLevels"
                               v-model="selectedGroupLevels"></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-duration" label="Durée du séjour :" label-for="input-duration" size="sm"
                          label-cols-sm="1" content-cols-sm="2"
                          label-align-sm="right">



                <b-form-select v-model="selectedGroupDurations" :disabled="disabledFilter">
                    <b-form-select-option :value="0">Toute durée</b-form-select-option>
                    <b-form-select-option v-for="gDuration in groupDurations" :key="gDuration.id" :value="gDuration.id">
                        {{ gDuration.label }}
                        <span v-if="gDuration.min == 0">( jusqu'à {{gDuration.max}} jours)</span>
                        <span v-if="gDuration.min > 0 && gDuration.max > 0">( de {{gDuration.min}} à {{gDuration.max}} jours)</span>
                        <span v-if="gDuration.max == 0">( au moins {{gDuration.min}} jours)</span>
                    </b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-button type="button" variant="primary" @click="productList">Filtrer</b-button>

            <b-card :sub-title="website.name" v-for="website in webSites" :id="'input-group-' + website.id" :key="website.id" v-show="website.enabled">
                <b-table striped hover :items="allProducts[(website.id) - 1].products" :fields="productsField" head-variant="light" sticky-header="300px" :busy="loading">
                    <template #cell(id)="data">
                        <b-form-checkbox class="spaced" type="checkbox" :id="'compareProduct'+data.value" :value="data.value" v-model="selectedProducts"></b-form-checkbox>
                    </template>

                    <template #cell(code)="data" style="width: 40px">
                        {{data.value.trim().toUpperCase()}}
                    </template>
                    <template #cell(countries)="data">
                        <img v-for="country in data.value" :key="country.name" :src="'/flags/'+country.id.toLowerCase()+'.gif'" :alt="country.name" :title="country.name" />
                    </template>
                    <template #cell(groupTypes)="data">
                        <div v-for="type in data.value" :key="type.label">{{type.label}}</div>
                    </template>
                    <template #cell(groupLevels)="data">
                        <div v-for="level in data.value" :key="level.label">{{level.label}}</div>
                    </template>
                    <template #cell(firstDeparture)="data">
                        {{data.value | moment('DD/MM/YYYY')}}
                    </template>
                    <template #cell(lastReturn)="data">
                        {{data.value | moment('DD/MM/YYYY')}}
                    </template>
                    <template #table-busy>
                        <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                        </div>
                    </template>
                </b-table>
            </b-card>
            <div style="float:right"><b-button type="button" variant="danger" @click="deleteGProduct" v-show="!groupExist">Supprimer</b-button></div>
            <b-button type="button" variant="primary" @click="postform" v-show="!groupExist">Valider</b-button>
            <b-button type="button" variant="warning" @click="resetform">Retour</b-button>
        </b-form>
    </b-card>
</template>

<script>
    import { mapState } from "vuex"
    import axios from 'axios'

    export default {
        name: 'EditGroupProduct',
        components: {

        },
        data() {
            return {
                groupExist: false,
                loading: false,
                productsField: [
                    {
                        key: 'id',
                        label: '',
                        class: 'checkboxcol',
                        thStyle: { width: '50px' }
                    },
                    {
                        key: 'code',
                        label: 'Produit',
                        sortable: false,
                        thStyle: { width: '100px' }
                    },
                    {
                        key: 'countries',
                        label: 'Country',
                        sortable: false,
                        thStyle: { width: '100px' }
                    },
                    {
                        key: 'title',
                        label: 'Titre',
                        sortable: false
                    },
                    {
                        key: 'duration',
                        label: 'Durée',
                        sortable: false,
                        thStyle: { width: '80px' }
                    },
                    {
                        key: 'groupTypes',
                        label: 'Type de produit',
                        sortable: false,
                        thStyle: { width: '200px' }
                    },
                    {
                        key: 'groupLevels',
                        label: 'Niveau',
                        sortable: false,
                        thStyle: { width: '120px' }
                    }
                ],
                allProducts: [],
                selectedProducts: [],
                listOptions: [],
                listCountries: [],
                selectedOption: 'group',
                selectedGC: 0,
                selectedGroupTypes: 0,
                selectedGroupLevels: 0,
                selectedGroupDurations: 0,
                selectedCountry: "",
                groupProduct: {},
                selectOptions: [
                    { value: "all", text: "Tous les produits" },
                    { value: "empty", text: "Produits non affectés" },
                    { value: "group", text: "Produits du groupe uniquement" },
                    { value: "groups", text: "Produits déjà affectés uniquement" }
                ],
                gCountriesFields: [
                    {
                        key: 'label',
                        label: 'Zone Géographique'
                    },
                    {
                        key: 'countries',
                        label: 'Pays associés'
                    },
                    {
                        key: 'id',
                        label: 'Action'
                    }]
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString().toLowerCase()

                return value.charAt(0).toUpperCase() + value.slice(1)
            },
            upper: function (value) {
                if (!value) return ''
                return value.toString().toUpperCase()
            },
            ln2br: function (value) {
                if (!value) return ''
                return value.toString().replace("\n", "<br>")
            },
            countryToLine: function (value) {
                if (value.length == 0) return ''
                let arrName = []
                value.forEach(vA => { arrName.push(vA.name) });
                arrName.sort((a, b) => {
                    return a.localeCompare(b);
                })
                return arrName.join(", ")
            },
        },
        props: {
            editId: Number
        },
        event: ['reset', 'save'],
        methods: {
            checkGroup: function () {
                this.groupExist = true
                if (this.groupProduct.label.length <= 0) return
                let self = this
                axios.post(this.$ipService + '/api/groupProducts/groupNameExists/' + this.groupProduct.id, 'gGroupName='+this.groupProduct.label).then(
                    response => {
                        self.groupExist = response.data
                    }
                )
            },
            resetform: function () {
                this.$emit('reset')
            },
            deleteGProduct: function () {
                let self = this
                if (confirm("voulez-vous vraiment supprimer le groupe de produit " + this.groupProduct.label + " ? ")) {
                    axios.delete(this.$ipService + '/api/GroupProducts/' + this.groupProduct.id
                    ).then(function () {
                        self.$store.dispatch('loadGroupcountries')
                        self.$store.dispatch('loadCountries')
                        self.$store.dispatch('loadCountriesActives')
                        self.$store.dispatch('loadGrouptypes')
                        self.$store.dispatch('loadGrouplevels')
                        self.$store.dispatch('loadWebsites')
                        self.$store.dispatch('loadGroupdurations')
                        self.$store.dispatch('loadGroupproducts')
                        self.$store.dispatch('loadGroupsOrProducts')
                    }).catch(function (error) {
                        console.log(error)
                    }).finally(() => {
                        this.$emit('reset')
                    })
                }
            },
            postform: function () {
                let objPost = {
                    id: this.groupProduct.id,
                    label: this.groupProduct.label,
                    comment: this.groupProduct.comment,
                    Products: [...new Set(this.selectedProducts)]
                }
                objPost.Products.sort()


                let self = this
                axios.post(this.$ipService + '/api/groupProducts/' + objPost.id, objPost
                ).then(function () {
                    self.$store.dispatch('loadGroupcountries')
                    self.$store.dispatch('loadCountries')
                    self.$store.dispatch('loadCountriesActives')
                    self.$store.dispatch('loadGrouptypes')
                    self.$store.dispatch('loadGrouplevels')
                    self.$store.dispatch('loadWebsites')
                    self.$store.dispatch('loadGroupdurations')
                    self.$store.dispatch('loadGroupproducts')
                    self.$store.dispatch('loadGroupsOrProducts')
                    self.$emit('reset')
                }).catch(function (error) {
                    console.log(error)
                }).finally(() => {

                })
                
            },
            loadProduction: function (inGroupProduct) {
                let currentProducts = {}
                this.selectedProducts = [];
                this.groupProduct.products.forEach(product => {
                    currentProducts[product.id] = product
                    this.selectedProducts.push(product.id)
                })
                //let GoupCountryIds = []
                let objQuery = {
                    AdapterIds: [],
                    GoupCountryIds: this.selectedGC > 0 && this.selectedCountry == "" ? [this.selectedGC] : [],
                    CountriesIds: this.selectedCountry != "" ? [this.selectedCountry] : [],
                    GroupTypesIds: this.selectedGroupTypes > 0 ? [this.selectedGroupTypes] : [],
                    GroupLevelsIds: this.selectedGroupLevels > 0 ? [this.selectedGroupLevels] : [],
                    DurationMinId: this.selectedGroupDurations > 0 ? this.selectedGroupDurations : null,
                    DurationMaxId: this.selectedGroupDurations > 0 ? this.selectedGroupDurations : null,
                    DateFrom: "",
                    DateTo: "",
                    PriceMin: 0,
                    PriceMax: 0,
                    GroupProductId: 0,
                    InGroupProduct: inGroupProduct,
                    ProductName: null,
                    DateGaranti: false
                }
                let self = this

                let allProducts = {}
                this.webSites.forEach(website => {
                    let objSite = {
                        id: website.adapterId, name: website.name, enabled: website.enabled, products: []
                    }
                    allProducts[website.adapterId] = objSite
                })

                axios.post(this.$ipService + '/api/Products/shortFiltered', objQuery
                ).then(function (response) {
                    let products = response.data

                    products.forEach(product => {
                        currentProducts[product.id] = product
                        
                    })
                }).catch(function (error) {
                    console.error(error);
                }).finally(() => {

                    let arrCurrentProduct = Object.values(currentProducts)
                    
                    arrCurrentProduct.sort((a, b) => {
                        if (self.selectedProducts.indexOf(a.id) >= 0 && self.selectedProducts.indexOf(b.id) < 0) {
                            return -1
                        }
                        if (self.selectedProducts.indexOf(a.id) < 0 && self.selectedProducts.indexOf(b.id) >= 0) {
                            return 1
                        }
                        return a.code.localeCompare(b.code)
                    })

                    arrCurrentProduct.forEach(product => {
                        allProducts[product.adapterId].products.push(product)
                    })

                    self.allProducts = Object.values(allProducts);

                    this.loading = false
                })
            },
            getGroupList: function () {
                this.selectedProducts = []
                let allProducts = {}
                this.webSites.forEach(website => {
                    let objSite = {
                        id: website.adapterId, name: website.name, enabled: website.enabled, products: []
                    }
                    allProducts[objSite.id] = objSite
                })
                let products = this.groupProduct.products
                products.forEach(product => {
                    allProducts[product.adapterId].products.push(product)
                    this.selectedProducts.push(product.id)
                })
                this.allProducts = Object.values(allProducts);
                this.loading = false
            },
            updateCountries: function () {
                this.selectedCountry = ""
                this.listCountries = [{ value: "", text: "Toutes" }]
                let listCountries = this.countries
                if (this.selectedGC > 0) {
                    for (let i = 0; i < this.groupCountries.length; i++) {
                        if (this.selectedGC == this.groupCountries[i].id) {
                            listCountries = this.groupCountries[i].countries
                            break;
                        }
                    }

                }
                listCountries.forEach(country => {
                    this.listCountries.push({ value: country.id, text: country.name })
                })
            },
            productList: function () {
                this.loading = true
                if (this.selectedOption == "all") {
                    this.loadProduction(null)
                    return
                }
                if (this.selectedOption == "empty") {
                    this.loadProduction(false)
                    return
                }
                if (this.selectedOption == "groups") {
                    this.loadProduction(true)
                    return
                }

                this.getGroupList()
            }
        },
        computed: {
            disabledFilter: function () {
                return this.selectedOption == "group"
            },
            listZoneGeo: function () {
                let zonesGeo = [{ value: 0, text: "Toutes" }]
                this.groupCountries.forEach(zoneGeo => {
                    zonesGeo.push({ value: zoneGeo.id, text: zoneGeo.label })
                })
                return zonesGeo
            },
            listGroupTypes: function () {
                let groupsTypes = [{ value: 0, text: "Tous" }]
                this.groupTypes.forEach(groupTypes => {
                    groupsTypes.push({ value: groupTypes.id, text: groupTypes.label })
                })
                return groupsTypes
            },
            listGroupLevels: function () {
                let groupsLevels = [{ value: 0, text: "Tous" }]
                this.groupLevels.forEach(groupLevel => {
                    groupsLevels.push({ value: groupLevel.id, text: groupLevel.label })
                })
                return groupsLevels
            },
            listGroupDurations: function () {
                let groupsDurations = [{ value: 0, text: "Toute durée" }]
                this.groupDurations.forEach(groupDuration => {
                    groupsDurations.push({ value: groupDuration.id, text: groupDuration.label })
                })
                return groupsDurations
            },
            ...mapState(["webSites", "groupDurations", "countries", "groupCountries", "groupLevels", "groupTypes", "currentDetail", "currentUser"])
        },
        mounted: function () {
            let self = this
            this.groupExist = false
            axios.get(this.$ipService + '/api/GroupProducts/' + this.editId
            ).then(function (response) {
                self.groupProduct = response.data
            }).catch(function (error) {
                console.log(error);
            }).finally(() => {
                let allProducts = {}
                this.allProducts = []
                this.selectedProducts = []
                this.webSites.forEach(website => {
                    let objSite = {
                        id: website.adapterId, name: website.name, enabled: website.enabled, products: []
                    }
                    allProducts[objSite.id] = objSite
                })
                this.groupProduct.products.forEach(product => {
                    allProducts[product.adapterId].products.push(product)
                    this.selectedProducts.push(product.id)
                })
                this.allProducts = Object.values(allProducts);
                this.updateCountries()
            })

        }
    };
</script>

<style scoped>
    .icon-span {
        margin: 5px;
    }

    .clickable {
        cursor: pointer
    }

    datatable td[aria-colindex="3"] {
        width: 100px;
    }
</style>
